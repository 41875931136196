<template>
  <svg preserveAspectRatio="xMidYMid meet"
       xmlns="http://www.w3.org/2000/svg"
       width="18.641px" height="16.779px" viewBox="0 0 18.641 16.779">
    <g class="cls-1" :style="style">
      <path d="M17.486,1.04H1.153 l6.534,7.725v5.342l3.267,1.633V8.765L17.486,1.04z"/>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconFeatherFilter = Vue.extend({
  name: 'icon-feather-filter',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});
export default IconFeatherFilter;
</script>

<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.75px;
  }
</style>
